/* eslint-disable no-console */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready: function ready() {
            console.log(
                'App is being served from cache by a service worker.\n'
        + 'For more details, visit https://goo.gl/AFskqB',
            );
        },
        registered: function registered() {
            console.log('Service worker has been registered.');
        },
        cached: function cached() {
            console.log('Content has been cached for offline use.');
        },
        updatefound: function updatefound() {
            console.log('New content is downloading.');
        },
        updated: function updated() {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' })
            console.log('New content is available; please refresh.');
        },
        offline: function offline() {
            console.log('No internet connection found. App is running in offline mode.');
        },
        error: function errorCallback(error) {
            console.error('Error during service worker registration:', error);
        },
    });
}
